<!--  -->
<template>
  <div>
    <van-sticky >
     <!-- 盘点类型 -->
     <div class="point_top">
        <i class="el-icon-arrow-left" @click="back()"></i>
        <!-- <div class="point_kind">{{ pointTitle.inventoryTypeName }}</div> -->
        <div class="point_kind">码上放心</div>
        <div class="point_point"></div>
      </div>
    </van-sticky>
    <div>
        <div style="width:96%;height:22vw;background:rgb(192, 192, 192,0.2);border-radius: 2vw;margin-left:2%;margin-top: 2vw;display: flex;align-items: center;" @click="arehousingScanning(1,tmpBarCode)">
             <div style="margin-left:5vw;color: #000000;">
              <div style="margin-left: 3.5vw;font-size:6vw;font-weight:600;">入库扫描 </div> 
              <div style="color:#6E6E6E;transform: scale(0.7);">Stock in </div>
              <div style="color:#6E6E6E;transform: scale(0.7);">scanning</div>
             </div>  
             <div style="margin-left:40vw"><img src="../assets/icon//caigou.png" alt="" style="width:16vw;height:18vw"></div>
        </div>
        <div style="width:96%;height:22vw;background:rgb(192, 192, 192,0.2);border-radius: 2vw;margin-left:2%;margin-top: 2vw;display: flex;align-items: center;" @click="testDiff()">
               <div style="margin-left:5vw">
                <div style="font-weight:600;margin-left: 3.5vw;font-size:6vw;">入库差异</div>
               <div style="color:#6E6E6E;transform: scale(0.7);">Receipt </div>
               <div style="color:#6E6E6E;transform: scale(0.7);">Variance</div>
               </div>
               <div style="margin-left:40vw">
                <img src="../assets/icon/baobiaochakan.png" alt="" style="width:16vw;height:18vw">
               </div>
        </div>
    </div>
    <div>
        <div style="width:96%;height:22vw;background:rgb(192, 192, 192,0.2);border-radius: 2vw;margin-left:2%;margin-top: 2vw;display: flex;align-items: center;" @click="outboundScanning(1,barCodeOutScanchuku)">
             <div style="margin-left:5vw;color: #000000;">
              <div style="margin-left: 3.5vw;font-size:6vw;font-weight:600;">出库扫描 </div> 
              <div style="color:#6E6E6E;transform: scale(0.7);">Stock out  </div>
              <div style="color:#6E6E6E;transform: scale(0.7);">scanning</div>
             </div>  
             <div style="margin-left:40vw"><img src="../assets/icon//caigou.png" alt="" style="width:16vw;height:18vw"></div>
        </div>
        <div style="width:96%;height:22vw;background:rgb(192, 192, 192,0.2);border-radius: 2vw;margin-left:2%;margin-top: 2vw;display: flex;align-items: center;" @click="testDiffOut()">
               <div style="margin-left:5vw">
                <div style="font-weight:600;margin-left: 3.5vw;font-size:6vw;">出库差异</div>
               <div style="color:#6E6E6E;transform: scale(0.7);">Issue</div>
               <div style="color:#6E6E6E;transform: scale(0.7);">Variance</div>
               </div>
               <div style="margin-left:40vw">
                <img src="../assets/icon/baobiaochakan.png" alt="" style="width:16vw;height:18vw">
               </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
        tmpBarCode:"",
      barCodeOutScanchuku:""
    }
  },
  created() {
    this.tmpBarCode=this.$route.params.barCode1;
    this.barCodeOutScanchuku=this.$route.params.barCodeOutScan
  },
  methods: {
    back() {
      //this.$router.go(-1);
      this.$router.push('/point');
    },
    // 入库差异
    testDiff(){
      this.$router.push(`./testDiff`)
    },
     // 出库差异
     testDiffOut(){
      this.$router.push(`./testDiffOut`)
    },
    // 出库扫描
    outboundScanning(index,Code){
      this.$router.push(`./outScanning/${index}/${Code}`)
    },
    //入库扫描
    arehousingScanning(index,Code){
      this.$router.push(`./arehousingScanning/${index}/${Code}`)
    },
  }
}

</script>
<style >
.point_top {
  width: 100%;
  height: 6vh;
  background-image: linear-gradient(#04a9f3, #4ec5fb);
  color: #ffffff;
  font-size: 4.6vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.point_point {
  font-size: 6vw;
  font-weight: bolder;
  margin-right: 3vw;
}
.point_top i {
  width: 25px;
  height: 20px;
  margin-left: 3vw;
  font-weight:600;
}
</style>